import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { Suspense, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { authToken, getUserStoredCountryCode } from "./v2_actions/auth";
import { setIsEventIframe, setIsPhotographerIframe } from "./v2_actions/nav";

import BookBoss from "./views/bookboss";
import CustomerProfilePage from "./v2_components/CustomerProfilePage";
import { EventsPage } from "./v2_components/eventpage/EventsPage";
import Feedback from "./views/feedback";
import GalleryPage from "./v2_components/GalleryPage";
import GalleryPhotographsParentTwo from "./v2_components/prototype/GalleryPhotographsParentTwo";
import GalleryPhotos from "./views/galleryphotographs";
import Help from "./views/help";
import HowWork from "./views/howwork";
import Login from "./views/login";
import MobileEvents from "./v3_views/events/MobileEvents";
import PhotoArtPage from "./v2_components/PhotoArtPage";
import PhotographerPhotoArtGalleryPage from "./views/galleryphotographs/PhotographerPhotoArtPhotos";
import Privacy from "./views/privacy";
import Purchased from "./views/purchashed";
import PurchasedScreen from "./v3_views/purchased/PurchasedScreen";
import SetPassword from "./views/setpassword";
import { SharedContextProvider } from "./v2_components/hooks/useSharedContext";
import ShippingAddressPage from "./v2_components/ShippingAddressPage";
import ShoppingTrolley from "./views/shoppingtrolley/checkout_v2";
import { SignOut } from "./views/SignOut";
import SignUp from "./views/signup";
import Terms from "./views/terms";
import TrolleyPhotographPage from "./v2_components/TrolleyPhotographPage";
import TrolleyScreen from "./v3_views/trolley/TrolleyScreen";
import { countryList } from "./v2_actions/country";
import { useCountryDetection } from "./v2_components/hooks/useCountryDetection";
import { useDispatch } from "react-redux";
import { useIsMobile } from "./actions/ui";
import { useReduxStateReconcilation } from "./v2_components/hooks/useReduxStateReconcilation";
import { useBrowserCloseEffect } from "../src/hooks/useBrowserCloseEffect";
import { clearSubPathTrolleyCookieId } from "../src/v2_actions/trolley_cookie";

const YourPhotographsScreen = React.lazy(
  () => import("./v3_views/privateevents/YourPhotographsScreen"),
);
const PrivateEventVerificationScreen = React.lazy(
  () => import("./v3_views/privateevents/PrivateEventVerificationScreen"),
);
const FindYourPhotosScreen = React.lazy(
  () => import("./v3_views/privateevents/FindYourPhotosScreen"),
);

// AsyncLoader can ONLY be used for non-MUI/Emotion based pages. when Emotion/React style corruption bug is fixed by Facebook in React 18
const AsyncLoader = ({ children }) => (
  <Suspense fallback={<div>Loading ...</div>}>{children}</Suspense>
);

const Loader = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    clearSubPathTrolleyCookieId();
  }, [location]);

  return <>{children}</>;
};

const theme = createTheme({});

function Redirector({ countryCode }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (countryCode && countryCode !== undefined) {
      navigate("/customer/events");
    } else if (countryCode.length === 0) {
      navigate("/customer/events");
    }
  }, [countryCode, navigate]);
}

function PhotographerRedirector() {
  const { country, photographerNickName } = useParams();
  const { persistCountry } = useCountryDetection();
  const navigate = useNavigate();

  useEffect(
    function redirectToEventsOnceResolved() {
      if ((country, photographerNickName)) {
        persistCountry(country); // set country in localstorage
        setIsPhotographerIframe();
        navigate(`/customer/events?photographers=${photographerNickName}`);
      } else {
        navigate("/customer/events");
      }
    },
    [country, navigate],
  );
}

const EventsRoute = ({ isMobile }) => {
  setIsEventIframe();
  return isMobile ? (
    <AsyncLoader>
      <MobileEvents />
    </AsyncLoader>
  ) : (
    <Loader>
      <EventsPage />
    </Loader>
  );
};

const MapRoute = ({ isMobile }) => {
  return isMobile ? (
    <AsyncLoader>
      <MobileEvents />
    </AsyncLoader>
  ) : (
    <Loader>
      <GalleryPage />
    </Loader>
  );
};

const ClearSession = () => {
  sessionStorage.clear();
  return <>Session cleared</>;
};

function ProtectedWrapper({ children }) {
  const navigate = useNavigate();
  const isAuthenticated = authToken();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/customer/login");
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return children;
}

function App() {
  useReduxStateReconcilation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const countryCode = getUserStoredCountryCode();

  useEffect(() => {
    dispatch(countryList.loadAllCountries());
  }, []);

  useBrowserCloseEffect();

  return (
    <MuiThemeProvider theme={theme}>
      <SharedContextProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path={"/clearsession"} element={<ClearSession />} />
            <Route
              path={"/customer/events"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path={"/signout"}
              element={
                <Loader>
                  <SignOut />
                </Loader>
              }
            />
            <Route
              path={"/customer/howitworks"}
              element={
                <Loader>
                  <HowWork />
                </Loader>
              }
            />
            <Route
              path={"/customer/login/:trolley_id_from_url"}
              element={<Login />}
            />
            <Route
              path={"/customer/login"}
              element={
                <Loader>
                  <Login />
                </Loader>
              }
            />
            <Route
              path={"/customer/signup/:trolley_id_from_url"}
              element={<SignUp />}
            />
            <Route
              path={"/customer/signup"}
              element={
                <Loader>
                  <SignUp />
                </Loader>
              }
            />
            <Route
              path={"/customer/setpassword"}
              element={
                <Loader>
                  <SetPassword />
                </Loader>
              }
            />
            <Route
              path={"/customer/map"}
              element={<MapRoute isMobile={isMobile} />}
            />
            <Route
              path={"/customer/events/:gallery_event_name/:album_name"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path={"/customer/events/:gallery_event_name"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path={"/customer/events"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path={"/customer/photo_art/:photo_art_genre_name"}
              element={
                <Loader>
                  <PhotoArtPage />
                </Loader>
              }
            />
            <Route
              path={"/customer/photo_art"}
              element={
                <Loader>
                  <PhotoArtPage />
                </Loader>
              }
            />
            <Route
              path={"/customer/shipping_address"}
              element={
                <Loader>
                  <ShippingAddressPage />
                </Loader>
              }
            />
            <Route
              path={"/customer/v2_trolley/:trolley_photograph_id"}
              element={
                <Loader>
                  <TrolleyPhotographPage />
                </Loader>
              }
            />
            <Route
              element={
                isMobile ? (
                  <Loader>
                    <GalleryPhotographsParentTwo />
                  </Loader>
                ) : (
                  <Loader>
                    <GalleryPhotos />
                  </Loader>
                )
              }
              path={"/customer/gallery/:gallery_session_name/:galleryId"}
            />
            <Route
              element={
                isMobile ? (
                  <Loader>
                    <GalleryPhotographsParentTwo />
                  </Loader>
                ) : (
                  <Loader>
                    <GalleryPhotos />
                  </Loader>
                )
              }
              path={
                "/customer/:gallery_event_name/:gallery_session_name/:galleryId"
              }
            />
            <Route
              element={
                <Loader>
                  <GalleryPhotographsParentTwo />
                </Loader>
              }
              path={
                "/customer/gallery_trolley/:gallery_session_name/:galleryId"
              }
            />
            <Route
              path={"/customer/photo_art_gallery/:galleryId"}
              element={
                <Loader>
                  <PhotographerPhotoArtGalleryPage />
                </Loader>
              }
            />
            <Route
              path={"/customer/help"}
              element={
                <Loader>
                  <Help />
                </Loader>
              }
            />
            <Route
              path={"/customer/terms"}
              element={
                <Loader>
                  <Terms />
                </Loader>
              }
            />
            <Route
              path={"/customer/privacy"}
              element={
                <Loader>
                  <Privacy />
                </Loader>
              }
            />
            <Route
              path={"/:country/customer/map/:photographerNickName"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path={"/:country/:photographerNickName"}
              element={
                <Loader>
                  <PhotographerRedirector />
                </Loader>
              }
            />
            <Route
              path={"/:publicUrlPathForGalleryEvent"}
              element={<EventsRoute isMobile={isMobile} />}
            />
            <Route
              path="/"
              element={<Redirector countryCode={countryCode} />}
            />
            <Route
              path="/customer"
              element={<Redirector countryCode={countryCode} />}
            />
            <Route
              path={"/customer/editprofile/:user_id"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <CustomerProfilePage />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/editprofile"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <CustomerProfilePage />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/shopping_legacy/:trolley_id"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <ShoppingTrolley />
                  </Loader>
                </ProtectedWrapper>
              }
            />

            <Route
              path={"/customer/shopping_legacy"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <ShoppingTrolley />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/v2_trolley_legacy"}
              element={<TrolleyPhotographPage />}
            />
            <Route
              path={"/customer/trolley_legacy"}
              element={
                <Loader>
                  <TrolleyPhotographPage />
                </Loader>
              }
            />
            <Route
              path={"/customer/shopping"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <TrolleyScreen />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/shopping/:trolley_id"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <TrolleyScreen />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/purchasedold"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <Purchased />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/purchased"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <PurchasedScreen />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/bookboss/:photographer_id"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <BookBoss />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/bookboss"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <BookBoss />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/customer/feedback"}
              element={
                <ProtectedWrapper>
                  <Loader>
                    <Feedback />
                  </Loader>
                </ProtectedWrapper>
              }
            />
            <Route
              path={"/:privateeventkey/private/verification"}
              element={
                <AsyncLoader>
                  <PrivateEventVerificationScreen />
                </AsyncLoader>
              }
            />
            <Route
              path={"/:privateeventkey/private/yourphotographs"}
              element={
                <AsyncLoader>
                  <YourPhotographsScreen />
                </AsyncLoader>
              }
            />
            <Route
              path={"/:privateeventkey/private/findyourphotos"}
              element={
                <AsyncLoader>
                  <FindYourPhotosScreen />
                </AsyncLoader>
              }
            />
          </Routes>
        </Router>
      </SharedContextProvider>
    </MuiThemeProvider>
  );
}

export default App;
